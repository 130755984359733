var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.formConf.fields.length > 0
        ? _c("parser", {
            directives: [
              {
                name: "hasPermi",
                rawName: "v-hasPermi",
                value: ["platform:system:form:info"],
                expression: "['platform:system:form:info']",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            key: _vm.keyNum,
            attrs: {
              "is-edit": _vm.isCreate === 1,
              "form-conf": _vm.formConf,
              "form-edit-data": _vm.editData,
            },
            on: { submit: _vm.handlerSubmit, resetForm: _vm.resetForm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }