import Layout from '@/layout';
var serviceRouter = {
  path: '/service',
  component: Layout,
  redirect: '/service/index',
  name: 'service',
  alwaysShow: true,
  meta: {
    title: '客服管理',
    icon: 's-custom'
  },
  children: [{
    path: 'keyword',
    component: function component() {
      return import('@/views/service/keyword/index');
    },
    name: 'keywordIndex',
    meta: {
      title: '客服自动回复'
    }
  }, {
    path: 'customer',
    component: function component() {
      return import('@/views/service/customer');
    },
    name: 'customerIndex',
    meta: {
      title: '客服列表'
    }
  }]
};
export default serviceRouter;