import Layout from '@/layout';
var userRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/index',
  name: 'User',
  meta: {
    title: '用户',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/user/list/index');
    },
    name: 'UserIndex',
    meta: {
      title: '用户列表',
      icon: ''
    }
  }, {
    path: 'level',
    component: function component() {
      return import('@/views/user/level');
    },
    name: 'Level',
    meta: {
      title: '用户等级',
      icon: ''
    },
    children: [{
      path: 'list',
      name: 'levelList',
      meta: {
        title: '等级列表',
        noCache: true
      },
      component: function component() {
        return import('@/views/user/level/list');
      }
    }, {
      path: 'config',
      name: 'levelConfig',
      meta: {
        title: '等级配置',
        noCache: true
      },
      component: function component() {
        return import('@/views/user/level/levelConfig');
      }
    }, {
      path: 'description',
      name: 'levelDescription',
      meta: {
        title: '等级说明',
        noCache: true
      },
      component: function component() {
        return import('@/views/user/level/description');
      }
    }]
  }, {
    path: 'label',
    component: function component() {
      return import('@/views/user/label/index');
    },
    name: 'Label',
    meta: {
      title: '用户标签',
      icon: ''
    }
  }, {
    path: 'grouping',
    component: function component() {
      return import('@/views/user/grouping/index');
    },
    name: 'grouping',
    meta: {
      title: '企业管理',
      icon: ''
    }
  }]
};
export default userRouter;