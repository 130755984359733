import Layout from '@/layout';
var productRouter = {
  path: '/product',
  component: Layout,
  redirect: '/product/index',
  name: 'Product',
  meta: {
    title: '商品',
    icon: 'clipboard'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/product/index');
    },
    name: 'ProductIndex',
    meta: {
      title: '商品列表',
      icon: ''
    }
  }, {
    path: 'merchandise',
    component: function component() {
      return import('@/views/merchandise/index');
    },
    name: 'MerchandiseIndex',
    meta: {
      title: '商品结算管理',
      icon: ''
    }
  }, {
    path: 'category',
    component: function component() {
      return import('@/views/product/category/index');
    },
    name: 'ProductCategory',
    meta: {
      title: '商品分类',
      icon: ''
    }
  }, {
    path: 'comment',
    component: function component() {
      return import('@/views/product/comment/index');
    },
    name: 'ProductComment',
    meta: {
      title: '商品评论',
      icon: ''
    }
  }, {
    path: 'brand',
    component: function component() {
      return import('@/views/product/brand/index');
    },
    name: 'ProductBrand',
    meta: {
      title: '品牌管理',
      icon: ''
    }
  }, {
    path: 'guarantee',
    component: function component() {
      return import('@/views/product/guarantee/index');
    },
    name: 'ProductGuarantee',
    meta: {
      title: '保障服务',
      icon: ''
    }
  }, {
    path: 'productLabel',
    component: function component() {
      return import('@/views/product/productLabel/index');
    },
    name: 'productLabel',
    meta: {
      title: '商品标签',
      icon: ''
    }
  }, {
    path: 'regionalBrand',
    component: function component() {
      return import('@/views/product/regionalBrand/index');
    },
    name: 'regionalBrand',
    meta: {
      title: '区域品牌',
      icon: ''
    }
  }, {
    path: 'management',
    component: function component() {
      return import('@/views/product/management/index');
    },
    name: 'management',
    meta: {
      title: '品牌分类',
      icon: ''
    }
  }]
};
export default productRouter;