import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
// import componentsRouter from './modules/components'
import productRouter from './modules/product';
import orderRouter from './modules/order';
import userRouter from './modules/user';
import collectivePurchaseRouter from './modules/collectivePurchase';
import distributionRouter from './modules/distribution';
import marketingRouter from './modules/marketing';
import financeRouter from './modules/finance';
import operationRouter from './modules/operation';
import merchant from './modules/merchant';
import pagediy from '@/router/modules/pagediy';
import classification from './modules/classification';
import serviceRouter from '@/router/modules/service';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 roles: ['admin','editor']    control the page roles (you can set multiple roles)
 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 noCache: true                if set true, the page will no be cached(default is false)
 affix: true                  if set true, the tag will affix in the tags-view
 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
 }
 */

/**
 *constantRoutes
 *没有权限要求的基页
 *所有角色都可以访问
 *
 */

export var constantRoutes = [classification,
// 商品
productRouter,
// 订单
orderRouter,
// 会员
userRouter,
// 分销
distributionRouter,
// 营销
marketingRouter,
// 财务
financeRouter,
// 设置
operationRouter,
//商户管理
merchant,
// 页面diy
pagediy,
//客服管理
serviceRouter,
// 集采
collectivePurchaseRouter, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/page/design/creatDevise/:id?',
  component: function component() {
    return import('@/views/pagediy/devise/creatDevise');
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
},
// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   name: 'Dashboard',
//   meta: {
//     title: '控制台',
//     icon: 'clipboard',
//   },
//   children: [
//     {
//       path: '/dashboard',
//       component: () => import('@/views/dashboard/index'),
//       name: 'DashboardIndex',
//       meta: { title: '主页', icon: 'dashboard' },
//     },
//   ],
// },
{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '主页',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/setting/uploadPicture',
  component: function component() {
    return import('@/components/base/uploadPicture.vue');
  },
  name: 'uploadPicture'
},
// 404 页面必须放在末尾 !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

/**
 * 异步路由
 * 需要根据用户角色动态加载的路由
 */
export var asyncRoutes = [];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    base: '/admin/',
    mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;