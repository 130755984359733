var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footers acea-row" }, [
    _c("div", {
      staticClass: "title mb15",
      domProps: { textContent: _vm._s(_vm.copyrightNew) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }