import Layout from '@/layout';
var collectivePurchaseRouter = {
  path: '/collectivePurchase',
  component: Layout,
  redirect: '/collectivePurchase/index',
  name: 'collectivePurchase',
  alwaysShow: true,
  meta: {
    title: '集采管理',
    icon: 's-custom'
  },
  children: [{
    path: 'address',
    component: function component() {
      return import('@/views/collectivePurchase/address/index');
    },
    name: 'addressdIndex',
    meta: {
      title: '地址管理'
    }
  }, {
    path: 'flowChart',
    component: function component() {
      return import('@/views/collectivePurchase/flowChart/index');
    },
    name: 'flowChartIndex',
    meta: {
      title: '审核流程管理'
    }
  }, {
    path: 'productLibrary',
    component: function component() {
      return import('@/views/collectivePurchase/productLibrary/index');
    },
    name: 'productLibraryIndex',
    meta: {
      title: '产品库'
    }
  }, {
    path: 'listIntentions',
    component: function component() {
      return import('@/views/collectivePurchase/listIntentions/index');
    },
    name: 'listIntentionsIndex',
    meta: {
      title: '意向清单'
    }
  }, {
    path: 'purchaseList',
    component: function component() {
      return import('@/views/collectivePurchase/purchaseList/index');
    },
    name: 'purchaseListIndex',
    meta: {
      title: '采购单列表'
    }
  }]
};
export default collectivePurchaseRouter;