import request from '@/utils/request';

/**
 * 商品详情
 * @param pram
 */
export function productDetailApi(id) {
  return request({
    url: "/admin/platform/product/info/".concat(id),
    method: 'GET'
  });
}

/**
 * 礼品卡
 * @param pram
 */
export function getGiftCard(params) {
  return request({
    url: "/admin/platform/card/usage/list",
    method: 'GET',
    params: params
  });
}

/**
 * 删除商品
 * @param pram
 */
export function productDeleteApi(id, type) {
  return request({
    url: "/admin/store/product/delete/".concat(id),
    method: 'get',
    params: {
      type: type
    }
  });
}

/**
 * 商品列表 表头数量
 */
export function productHeadersApi() {
  return request({
    url: '/admin/platform/product/tabs/headers',
    method: 'GET'
  });
}

/**
 * 商品列表
 * @param pram
 */
export function productLstApi(params) {
  return request({
    url: '/admin/platform/product/list',
    method: 'GET',
    params: params
  });
}

/**
 * 商品审核
 * @param pram
 */
export function productAuditApi(data) {
  return request({
    url: "/admin/platform/product/audit",
    method: 'post',
    data: data
  });
}

/**
 * 批量复制商品
 * @param pram
 */
export function productProductCopy(data) {
  return request({
    url: "admin/platform/product/copy",
    method: 'post',
    data: data
  });
}

/**
 * 修改虚拟销量
 * @param pram
 */
export function onChangeSalesApi(data) {
  return request({
    url: "/admin/platform/product/virtual/sales",
    method: 'post',
    data: data
  });
}

/**
 * 商品分类 分类缓存树
 * @param pram
 */
export function productCategoryApi() {
  return request({
    url: '/admin/platform/product/category/cache/tree',
    method: 'GET'
  });
}
/**
 * 商品分类 列表
 * @param pram
 */
export function productCategoryListApi(params) {
  return request({
    url: '/admin/platform/product/category/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品分类 新增
 * @param pram
 */
export function productCategoryAddApi(data) {
  return request({
    url: '/admin/platform/product/category/add',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 编辑
 * @param pram
 */
export function productCategoryUpdateApi(data) {
  return request({
    url: '/admin/platform/product/category/update',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 删除
 * @param pram
 */
export function productCategoryDeleteApi(id) {
  return request({
    url: "/admin/platform/product/category/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 商品分类 修改分类显示状态
 * @param pram
 */
export function productCategoryShowApi(id) {
  return request({
    url: "/admin/platform/product/category/update/show/".concat(id),
    method: 'post'
  });
}

/**
 * 商品分类 修改分类推进
 * @param pram
 */
export function updateIshot(id) {
  return request({
    url: "/admin/platform/product/category/update/ishot/".concat(id),
    method: 'post'
  });
}

/**
 * 商品上架
 * @param pram
 */
export function putOnShellApi(id) {
  return request({
    url: "/admin/store/product/putOnShell/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品强制下架
 * @param pram
 */
export function offShellApi(data) {
  return request({
    url: "/admin/platform/product/force/down",
    method: 'post',
    data: data
  });
}

/**
 * 商品推荐
 * @param pram
 */
export function updateRecommend(id) {
  return request({
    url: "/admin/platform/product/update/recommend/".concat(id),
    method: 'post'
  });
}

/**
 * 商品评论 列表
 * @param pram
 */
export function replyListApi(params) {
  return request({
    url: '/admin/platform/product/reply/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品评论 新增
 * @param pram
 */
export function replyCreatApi(data) {
  return request({
    url: '/admin/store/product/reply/save',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 编辑
 * @param pram
 */
export function replyEditApi(data) {
  return request({
    url: '/admin/store/product/reply/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 详情
 * @param pram
 */
export function replyInfoApi(id) {
  return request({
    url: "/admin/store/product/reply/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 删除
 * @param pram
 */
export function replyDeleteApi(id) {
  return request({
    url: "/admin/platform/product/reply/delete/".concat(id),
    method: 'GET'
  });
}

/**
 * 商品评论 导出
 * @param pram
 */
export function productExportApi(params) {
  return request({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品复制 99Api
 * @param pram
 */
export function importProductApi(params) {
  return request({
    url: "/admin/store/product/importProduct",
    method: 'post',
    params: params
  });
}

/**
 * 商品复制 一号通
 * @param pram
 */
export function copyProductApi(data) {
  return request({
    url: "/admin/store/product/copy/product",
    method: 'post',
    data: data
  });
}

/**
 * 恢复
 * @param pram
 */
export function restoreApi(id) {
  return request({
    url: "/admin/store/product/restore/".concat(id),
    method: 'get'
  });
}

/**
 * 商品列表 导出
 * @param pram
 */
export function productExcelApi(params) {
  return request({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品列表 获取复制商品配置
 * @param pram
 */
export function copyConfigApi() {
  return request({
    url: "/admin/store/product/copy/config",
    method: 'post'
  });
}

/**
 * 订单数据 导出
 * @param pram
 */
export function orderExcelApi(params) {
  return request({
    url: "/admin/export/excel/order",
    method: 'get',
    params: params
  });
}

/**
 * 品牌 列表
 * @param pram
 */
export function brandListApi(params) {
  return request({
    url: "/admin/platform/product/brand/list",
    method: 'get',
    params: params
  });
}
export function brandListApiPage(data) {
  return request({
    url: "/admin/platform/product/brand/list/page",
    method: 'POST',
    data: data
  });
}
/**
 * 品牌缓存列表(全部)
 * @param pram
 */
export function brandListAllApi() {
  return request({
    url: "/admin/platform/product/brand/cache/list",
    method: 'get'
  });
}

/**
 * 品牌 新增
 * @param pram
 */
export function brandAddApi(data) {
  return request({
    url: "/admin/platform/product/brand/add",
    method: 'post',
    data: data
  });
}
export function brandAddApiByplat(data) {
  return request({
    url: "/admin/platform/product/brand/add/byplat",
    method: 'post',
    data: data
  });
}

/**
 * 品牌 编辑
 * @param pram
 */
export function brandUpdateApi(data) {
  return request({
    url: "/admin/platform/product/brand/update",
    method: 'post',
    data: data
  });
}
export function brandUpdateApiByplat(data) {
  return request({
    url: "/admin/platform/product/brand/update/byplat",
    method: 'post',
    data: data
  });
}

/**
 * 品牌 删除
 * @param pram
 */
export function brandDeleteApi(id) {
  return request({
    url: "/admin/platform/product/brand/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 品牌 修改品牌显示状态
 * @param pram
 */
export function brandShowApi(id) {
  return request({
    url: "/admin/platform/product/brand/cate/status/change/".concat(id),
    method: 'get'
  });
}

/**
 * 区域品牌
 * @param pram
 */
export function statusChange(id) {
  return request({
    url: "/admin/platform/product/area/brand/cate/status/change/".concat(id),
    method: 'get'
  });
}

/**
 * 品牌 修改品牌显示状态
 * @param pram
 */
export function brandShowApiupdate(id) {
  return request({
    url: "/admin/platform/product/brand/update/show/".concat(id),
    method: 'post'
  });
}
/**
 * 保障服务 列表
 * @param pram
 */
export function guaranteeListApi(params) {
  return request({
    url: "/admin/platform/product/guarantee/list",
    method: 'get',
    params: params
  });
}

/**
 * 保障服务 新增
 * @param pram
 */
export function guaranteeAddApi(data) {
  return request({
    url: "/admin/platform/product/guarantee/add",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务 编辑
 * @param pram
 */
export function guaranteeUpdateApi(data) {
  return request({
    url: "/admin/platform/product/guarantee/update",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务 删除
 * @param pram
 */
export function guaranteeDeleteApi(id) {
  return request({
    url: "/admin/platform/product/guarantee/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 保障服务 修改品牌显示状态
 * @param pram
 */
export function guaranteeShowApi(id) {
  return request({
    url: "/admin/platform/product/guarantee/update/show/".concat(id),
    method: 'post'
  });
}

/**
 * 修改商品
 * @param pram
 */
export function updateProductApi(data) {
  return request({
    url: "/admin/platform/product/update",
    method: 'post',
    data: data
  });
}

/**
 * 商品可用优惠券列表
 * @param pram
 */
export function productCouponListApi() {
  return request({
    url: '/admin/platform/coupon/product/usable/list',
    method: 'get'
  });
}

/**
 * 商品搜索分页列表（活动）
 * @param pram
 */
export function productActivityListApi(params) {
  return request({
    url: '/admin/platform/product/activity/search/page',
    method: 'get',
    params: params
  });
}

/**
 * 根据商品id集合查询商品列表
 * @param pram
 */
export function productListbyidsApi(ids) {
  return request({
    url: "/admin/platform/product/listbyids/".concat(ids),
    method: 'get'
  });
}

/**
 * 商户商品标签
 * @param pram
 */
export function productLabelPage(data) {
  return request({
    url: "/admin/platform/product/cert/label/page",
    method: 'post',
    data: data
  });
}

/**
 *  商户商品新增
 *  @param pram
*/
export function productLabelSave(data) {
  return request({
    url: "/admin/platform/product/cert/label/save",
    method: 'post',
    data: data
  });
}

/**
 * 删除商品商户
 * @param pram
 */
export function productLabelDelete(id) {
  return request({
    url: "/admin/platform/product/cert/label/delete/".concat(id),
    method: 'DELETE'
  });
}

/**
 *  商户商品修改
 *  @param pram
*/
export function productLabelUpdate(data) {
  return request({
    url: "/admin/platform/product/cert/label/update",
    method: 'put',
    data: data
  });
}

/**
 *  区域品牌列表
 *  @param pram
*/
export function productAreaBrandPage(data) {
  return request({
    url: "/admin/platform/product/area/brand/page",
    method: 'POST',
    data: data
  });
}
/**
 *  区域品牌添加
 *  @param pram
*/
export function productAreaBrandSave(data) {
  return request({
    url: "/admin/platform/product/area/brand/save",
    method: 'POST',
    data: data
  });
}
/**
 *  区域品牌修改
 *  @param pram
*/
export function productAreaBrandUpdate(data) {
  return request({
    url: "/admin/platform/product/area/brand/update",
    method: 'PUT',
    data: data
  });
}

/**
 *  区域品牌修改
 *  @param pram
*/
export function productbAreaBrandDel(id) {
  return request({
    url: "/admin/platform/product/area/brand/delete/".concat(id),
    method: 'DELETE'
  });
}

/**
 *  品牌主列表,筛选条件分类i
 *  @param pram
*/
export function productBrandListPage(data) {
  return request({
    url: "/admin/platform/product/brand/list/page/wait_audit",
    method: 'post',
    data: data
  });
}

/**
 *  品牌主列表,筛选条件分类i
 *  @param pram
*/
export function productBrandAuditCurrent(data) {
  return request({
    url: "/admin/platform/product/brand/audit/current",
    method: 'post',
    data: data
  });
}

/**
 *  品牌主列表,品牌分类列表(树)
 *  @param pram
*/
export function productBrandCategoryList(data) {
  return request({
    url: "/admin/platform/product/brand/category/list",
    method: 'post',
    data: data
  });
}

/**
 *  品牌主列表,所属(商品)类目--下拉框展示
 *  @param pram
*/
export function productBrandCategoryTop(data) {
  return request({
    url: "/admin/platform/product/brand/category/top",
    method: 'GET',
    data: data
  });
}

/**
 *  品牌主列表,筛选条件分类i
 *  @param pram
*/
export function productBrandDelete(id) {
  return request({
    url: "/admin/platform/product/brand/category/delete/".concat(id),
    method: 'post'
  });
}

/**
 *  品牌主列表,筛选条件分类i
 *  @param pram
*/
export function productBrandCateTree(data) {
  return request({
    url: "/admin/platform/product/brand/cate/tree",
    method: 'get',
    data: data
  });
}

/**
 *  品牌主列表,新增
 *  @param pram
*/
export function productBrandCategory(data) {
  return request({
    url: "/admin/platform/product/brand/category/add",
    method: 'post',
    data: data
  });
}

/**
 *  品牌主列表,回显
 *  @param pram
*/
export function productBrandCategoryId(id) {
  return request({
    url: "/admin/platform/product/brand/category/".concat(id),
    method: 'get'
  });
}

/**
 *  品牌主列表,回显
 *  @param pram
*/
export function productBrandCategoryUpdate(data) {
  return request({
    url: "/admin/platform/product/brand/category/update",
    method: 'post',
    data: data
  });
}

/**
 *  品牌详情->已授权商家
 *  @param pram
*/
export function brandAuthorizedList(data) {
  return request({
    url: "/admin/platform/product/brand/authorized/list",
    method: 'post',
    data: data
  });
}

/**
 *  品牌详情->已授权商家
 *  @param pram
*/
export function brandWaitAuditList(data) {
  return request({
    url: "/admin/platform/product/brand/wait_audit/list",
    method: 'post',
    data: data
  });
}

/**
 *  品牌详情->已授权商家
 *  @param pram
*/
export function brandAuditHistoryList(data) {
  return request({
    url: "/admin/platform/product/brand/audit_history/list",
    method: 'post',
    data: data
  });
}

/**
 *  品牌详情->已授权商家
 *  @param pram
*/
export function brandAuditHistoryDoc(id) {
  return request({
    url: "/admin/platform/product/brand/doc/".concat(id),
    method: 'get'
  });
}

/**
 *  品牌详情->已授权商家
 *  @param pram
*/
export function brandAuditDocAudit(data) {
  return request({
    url: "/admin/platform/product/brand/doc/audit",
    method: 'post',
    data: data
  });
}

/**
 *  品牌详情->已授权商家
 *  @param pram
*/
export function brandAuditRevoke(id) {
  return request({
    url: "/admin/platform/product/brand/revoke/".concat(id),
    method: 'get'
  });
}
/**
 *  商品结算管理 -> 列表
 *  @param pram
*/
export function specialList(params) {
  return request({
    url: "/admin/platform/product/special/list",
    method: 'get',
    params: params
  });
}
/**
 * 商品结算管理 表头数量
 */
export function specialTabsHeaders(data) {
  return request({
    url: "/admin/platform/product/special/tabs/headers",
    method: 'POST',
    data: data
  });
}

/**
 * 商品结算管理
 * @param pram
 */
export function specialInfo(id) {
  return request({
    url: "/admin/platform/product/special/info/".concat(id),
    method: 'GET'
  });
}

/**
 * 商品结算管理
 * @param pram
 */
export function specialUpdate(data) {
  return request({
    url: "/admin/platform/product/special/update",
    method: 'post',
    data: data
  });
}

/**
 * 商品结算管理 ---提审
 * @param pram
 */
export function submitAudit(id) {
  return request({
    url: "/admin/platform/product/special/submit/audit/".concat(id),
    method: 'GET'
  });
}

/**
 * 商品结算管理 ---提审
 * @param pram
 */
export function specialAudit(data) {
  return request({
    url: "/admin/platform/product/special/audit",
    method: 'post',
    data: data
  });
}

/**
 * 商品结算管理导出列表
 */
export function merchandiseDownload(params) {
  return request({
    url: "/admin/platform/export/product/price/info",
    method: 'get',
    params: params
  });
}

/**
 * 商品列表导出
 */
export function productListDownload(params) {
  return request({
    url: "/admin/platform/export/product/basic/info",
    method: 'GET',
    params: params
  });
}