import Layout from '@/layout';
var merchantRouter = {
  path: '/merchant',
  component: Layout,
  redirect: '/merchant',
  name: 'Merchant',
  meta: {
    title: '商户',
    icon: 'clipboard'
  },
  children: [{
    path: 'classify',
    name: 'MerchantAlassify',
    component: function component() {
      return import('@/views/merchant/classify');
    },
    meta: {
      title: '商户分类',
      icon: ''
    }
  }, {
    path: 'list',
    name: 'MerchantList',
    component: function component() {
      return import('@/views/merchant/list');
    },
    meta: {
      title: '商户列表',
      icon: ''
    }
  }, {
    path: 'system',
    name: 'MerchantAystem',
    component: function component() {
      return import('@/views/merchant/system');
    },
    meta: {
      title: '商户菜单管理',
      icon: ''
    }
  }, {
    path: 'application',
    name: 'MerchantApplication',
    component: function component() {
      return import('@/views/merchant/application');
    },
    meta: {
      title: '商户入驻申请',
      icon: ''
    }
  }, {
    path: 'type',
    name: 'MerchantType',
    component: function component() {
      return import('@/views/merchant/type');
    },
    meta: {
      title: '店铺类型',
      icon: 'clipboard'
    },
    children: [{
      path: 'list',
      component: function component() {
        return import('@/views/merchant/type/list');
      },
      name: 'MerchantTypeList',
      meta: {
        title: '店铺类型',
        icon: ''
      }
    }]
  }, {
    path: 'supplier',
    name: 'MerchantSupplier',
    component: function component() {
      return import('@/views/merchant/supplier');
    },
    meta: {
      title: '供应商',
      icon: ''
    }
  }]
};
export default merchantRouter;