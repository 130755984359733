import Layout from '@/layout';
var pageDiyRouter = {
  path: '/page',
  component: Layout,
  redirect: '/page/design',
  name: 'PageDiy',
  meta: {
    title: '装修',
    icon: 'clipboard'
  },
  children: [{
    path: 'design',
    name: 'design',
    alwaysShow: true,
    component: function component() {
      return import('@/views/pagediy');
    },
    meta: {
      title: '页面管理',
      roles: ['admin']
    },
    children: [{
      path: 'devise',
      component: function component() {
        return import('@/views/pagediy/devise/index');
      },
      name: 'viewDevise',
      meta: {
        title: '页面装修',
        noCache: true
      }
    }, {
      path: 'creatDevise/:id?',
      name: 'creatDevise',
      component: function component() {
        return import('@/views/pagediy/devise/creatDevise');
      },
      meta: {
        title: '装修页面',
        activeMenu: "/pagediy/devise/index"
      },
      hidden: false
    }, {
      path: 'viewDesign',
      component: function component() {
        return import('@/views/pagediy/viewDesign');
      },
      name: 'viewDesign',
      meta: {
        title: '页面设计',
        noCache: true
      }
    }, {
      path: 'theme',
      component: function component() {
        return import('@/views/pagediy/theme');
      },
      name: 'theme',
      meta: {
        title: '一键换色',
        noCache: true
      }
    }, {
      path: 'picture',
      name: 'picture',
      component: function component() {
        return import('@/views/maintain/picture');
      },
      meta: {
        title: '素材管理',
        icon: 'clipboard'
      },
      hidden: false
    }]
  }]
};
export default pageDiyRouter;