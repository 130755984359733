import request from '@/utils/request';

/**
 * 角色详情
 */
export function getRoleById(pram) {
  return request({
    url: "/admin/system/role/info/".concat(pram.roles),
    method: 'GET'
  });
}

/**
 * 菜单
 * @param pram
 */
export function menuListApi() {
  return request({
    url: '/admin/platform/getMenus',
    method: 'GET'
  });
}