import Element from 'element-ui';
import '@/styles/element-variables.scss';
import couponFromComponent from './index.vue';
import Vue from 'vue';
import Cookies from 'js-cookie';
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

/**
 * 优惠券弹窗组件
 * @type {{}}
 * handle 是否展示选择框
 * keyNum 用于重置组件使用
 * coupons 选中的数据
 * callback 回调方法
 * userIds 用户id
 * userType 用户类型
 */
var couponFrom = {};
couponFrom.install = function (Vue, options) {
  var ToastConstructor = Vue.extend(couponFromComponent);
  // 生成一个该子类的实例
  var instance = new ToastConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$modalCoupon = function (handle, keyNum) {
    var coupons = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var callback = arguments.length > 3 ? arguments[3] : undefined;
    var userIds = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    var userType = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : '';
    instance.visible = true;
    instance.handle = handle;
    instance.keyNum = keyNum;
    instance.coupons = coupons;
    instance.userIds = userIds;
    instance.callback = callback;
    instance.userType = userType;
  };
};
export default couponFrom;