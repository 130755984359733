import request from '@/utils/request';
export function getFormConfigInfo(pram) {
  var data = {
    id: pram.id
  };
  return request({
    url: '/admin/platform/system/form/temp/info',
    method: 'GET',
    params: data
  });
}
export function getFormConfigList(pram) {
  var data = {
    keywords: pram.keywords,
    page: pram.page,
    limit: pram.limit
  };
  return request({
    url: '/admin/platform/system/form/temp/list',
    method: 'GET',
    params: data
  });
}
export function getFormConfigSave(pram) {
  var data = {
    content: pram.content,
    info: pram.info,
    name: pram.name
  };
  return request({
    url: '/admin/platform/system/form/temp/save',
    method: 'POST',
    data: data
  });
}
export function getFormConfigEdit(pram) {
  var params = {
    id: pram.id
  };
  var data = {
    content: pram.content,
    info: pram.info,
    name: pram.name
  };
  return request({
    url: '/admin/platform/system/form/temp/update',
    method: 'POST',
    params: params,
    data: data
  });
}

/**
 * 系统通知列表
 * @param pram
 */
export function notificationListApi(pram) {
  var data = {
    sendType: pram.sendType
    //发送类型（1：通知会员，2：通知平台）
  };
  return request({
    url: '/admin/platform/notification/list',
    method: 'GET',
    params: data
  });
}

/**
 * 小程序订阅模板开关
 * @param pram
 */
export function notificationRoutine(id) {
  return request({
    url: "/admin/platform/notification/routine/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 公众号模板开关
 * @param pram
 */
export function notificationWechat(id) {
  return request({
    url: "/admin/platform/notification/wechat/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 发送短信开关
 * @param pram
 */
export function notificationSms(id) {
  return request({
    url: "/admin/platform/notification/sms/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 通知详情
 * @param pram
 */
export function notificationDetail(param) {
  var data = {
    detailType: param.type,
    id: param.id
  };
  return request({
    url: "/admin/platform/notification/detail",
    method: 'get',
    params: data
  });
}

//admin/system/notification/detail
/**
 * 修改通知
 * @param pram
 */
export function notificationUpdate(param) {
  var data = {
    detailType: param.type,
    id: param.id,
    status: param.status,
    tempId: param.tempId
  };
  return request({
    url: "/admin/platform/notification/update",
    method: 'post',
    data: data
  });
}

/**
 * 通过名称查询详情
 * @param pram
 */
export function formTempNameInfoApi(params) {
  return request({
    url: "/admin/platform/system/form/temp/name/info",
    method: 'get',
    params: params
  });
}