//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'keyword',
  props: {
    isDisabled: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    labelarr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: function _default() {
        return 'text';
      }
    }
  },
  data: function data() {
    return {
      val: ''
    };
  },
  methods: {
    labelhandleClose: function labelhandleClose(tag) {
      var index = this.labelarr.indexOf(tag);
      this.labelarr.splice(index, 1);
    },
    addlabel: function addlabel() {
      var count = this.labelarr.indexOf(this.val);
      if (count === -1) {
        this.labelarr.push(this.val);
      }
      this.val = '';
      this.$emit('getLabelarr', this.labelarr);
    }
  }
};