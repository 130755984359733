import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/admin/platform/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/admin/platform/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/admin/platform/logout',
    method: 'get'
  });
}

/**
 * 用户推广员身份更改
 * 
 */
export function userPromoter(ids) {
  return request({
    url: "/admin/platform/user/promoter/status/switch/".concat(ids),
    method: 'get'
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
export function userListApi(params) {
  return request({
    url: "/admin/platform/user/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 修改
 * @param pram
 */
export function userUpdateApi(params, data) {
  return request({
    url: "/admin/platform/user/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 会员管理等级 修改
 * @param pram
 */
export function userLevelUpdateApi(data) {
  return request({
    url: "/admin/user/update/level",
    method: 'post',
    data: data
  });
}

/**
 * 会员管理 详情
 * @param pram
 */
export function userInfoApi(params) {
  return request({
    url: "/admin/user/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 账户详情
 * @param pram
 */
export function infobyconditionApi(params) {
  return request({
    url: "/admin/user/infobycondition",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 账户详情top数据
 * @param pram
 */
export function topdetailApi(params) {
  return request({
    url: "/admin/user/topdetail",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 批量设置分组
 * @param pram
 */
export function groupPiApi(params) {
  return request({
    url: "/admin/user/group",
    method: 'post',
    params: params
  });
}

/**
 * 会员管理 批量设置标签
 * @param pram
 */
export function tagPiApi(data) {
  return request({
    url: "/admin/platform/user/tag",
    method: 'post',
    data: data
  });
}

/**
 * 会员管理 积分余额
 * @param pram
 */
export function foundsApi(params) {
  return request({
    url: "/admin/platform/user/operate/integer",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 积分余额
 * @param pram
 */
export function balanceApi(params) {
  return request({
    url: "/admin/platform/user/operate/balance",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 删除
 * @param pram
 */
export function userDeleteApi(params) {
  return request({
    url: "/admin/user/delete",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 列表
 * @param pram
 */
export function levelListApi() {
  return request({
    url: "/admin/platform/system/user/level/list",
    method: 'get'
  });
}

/**
 * 会员等级 新增
 * @param pram
 */
export function levelSaveApi(data) {
  return request({
    url: "/admin/platform/system/user/level/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员等级 编辑
 *  @param pram
 */
export function levelUpdateApi(data) {
  return request({
    url: "/admin/platform/system/user/level/update",
    method: 'post',
    data: data
  });
}

/**
 * 会员等级 详情
 * @param pram
 */
export function levelInfoApi(params) {
  return request({
    url: "/admin/system/user/level/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 删除
 * @param pram
 */
export function levelDeleteApi(id) {
  return request({
    url: "/admin/platform/system/user/level/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 会员等级 是否显示
 * @param pram
 */
export function levelUseApi(data) {
  return request({
    url: "/admin/platform/system/user/level/use",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 列表
 * @param pram
 */
export function tagListApi(params) {
  return request({
    url: "/admin/platform/user/tag/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 新增
 * @param pram
 */
export function tagSaveApi(data) {
  return request({
    url: "/admin/platform/user/tag/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 编辑
 * @param pram
 */
export function tagUpdateApi(data) {
  return request({
    url: "/admin/platform/user/tag/update",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 详情
 * @param pram
 */
export function tagInfoApi(params) {
  return request({
    url: "/admin/user/tag/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 删除
 * @param pram
 */
export function tagDeleteApi(id) {
  return request({
    url: "/admin/platform/user/tag/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 用户标签全部列表
 */
export function tagAllListApi(id) {
  return request({
    url: "/admin/platform/user/tag/all/list",
    method: 'get'
  });
}

/**
 *获取登录页图片
 */
export function getLoginPicApi() {
  return request({
    url: "/admin/platform/getLoginPic",
    method: 'get'
  });
}

/**
 * @description 验证码
 */
export function captchaApi() {
  return request({
    url: "/publicly/validate/code/get",
    method: 'get'
  });
}

/**
 * @description 修改上级推广人
 */
export function updateSpreadApi(data) {
  return request({
    url: "/admin/platform/retail/store/update/user/spread",
    method: 'post',
    data: data
  });
}

/**
 * @description 修改手机号
 */
export function updatePhoneApi(params) {
  return request({
    url: "/admin/user/update/phone",
    method: 'get',
    params: params
  });
}

/**
 * @description 用户详情列表
 */
export function userDetailApi(id) {
  return request({
    url: "/admin/platform/user/detail/".concat(id),
    method: 'get'
  });
}

/**
 * @description 获取用户等级配置
 */
export function systemUserLevelConfigApi(id) {
  return request({
    url: "/admin/platform/system/user/level/get/config",
    method: 'GET'
  });
}

/**
 * @description 编辑用户等级配置
 */
export function systemUserLevelUpdateConfigApi(data) {
  return request({
    url: "/admin/platform/system/user/level/update/config",
    method: 'POST',
    data: data
  });
}

/**
 * @description 获取用户等级规则
 */
export function systemUserLevelRuleApi(id) {
  return request({
    url: "/admin/platform/system/user/level/get/rule",
    method: 'GET'
  });
}

/**
 * @description 编辑用户等级规则
 */
export function systemUserLevelUpdateRuleApi(data) {
  return request({
    url: "/admin/platform/system/user/level/update/rule",
    method: 'POST',
    data: data
  });
}

/**
 * @description 用户分组 --- 分页查询
 */
export function platformUserGroup(data) {
  return request({
    url: "/admin/platform/user/group/page",
    method: 'POST',
    data: data
  });
}

/**
 * @description 用户分组 --- 新增
 */
export function platformUserGroupSave(data) {
  return request({
    url: "/admin/platform/user/group/save",
    method: 'POST',
    data: data
  });
}

/**
 * @description 用户分组 --- 新增
 */
export function platformUserGroupupdate(data) {
  return request({
    url: "/admin/platform/user/group/update",
    method: 'PUT',
    data: data
  });
}

/**
 * @description 用户分组 -- 删除
 */
export function platformUserGroupDelete(id) {
  return request({
    url: "/admin/platform/user/group/delete/".concat(id),
    method: 'DELETE'
  });
}

/**
 * @description 用户列表 -- 余额开关
 */
export function yueEditCode(params) {
  return request({
    url: "/publicly/validate/code/get/yue_edit/code",
    method: 'get',
    params: params
  });
}

/**
 * @description 用户列表 -- 发送短信
 */
export function balanceSwitch(ids) {
  return request({
    url: "/admin/platform/user/operate/balance/switch/".concat(ids),
    method: 'get'
  });
}

/**
 * @description 用户选择城市
 */
export function groupCityTree(params) {
  return request({
    url: "/admin/platform/user/group/city_tree",
    method: 'get',
    params: params
  });
}