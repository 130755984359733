import Layout from '@/layout';
var operationRouter = {
  path: '/operation',
  component: Layout,
  redirect: '/operation/setting',
  name: 'Operation',
  meta: {
    title: '设置',
    icon: 'clipboard',
    roles: ['admin']
  },
  children: [{
    path: 'setting',
    name: 'setting',
    component: function component() {
      return import('@/views/systemSetting/setting');
    },
    meta: {
      title: '平台设置',
      icon: 'clipboard'
    }
  }, {
    path: 'agreement',
    name: 'OperationAgreement',
    component: function component() {
      return import('@/views/systemSetting/agreement');
    },
    meta: {
      title: '协议管理',
      icon: 'clipboard'
    }
  }, {
    path: 'notification',
    name: 'notification',
    component: function component() {
      return import('@/views/systemSetting/notification');
    },
    meta: {
      title: '消息通知',
      icon: 'clipboard'
    }
  }, {
    path: 'roleManager',
    name: 'RoleManager',
    component: function component() {
      return import('@/views/systemSetting/administratorAuthority');
    },
    meta: {
      title: '管理权限',
      icon: 'clipboard',
      roles: ['admin']
    },
    children: [{
      path: 'identityManager',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/identityManager');
      },
      name: 'identityManager',
      meta: {
        title: '角色管理',
        icon: ''
      }
    }, {
      path: 'adminList',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/adminList');
      },
      name: 'adminList',
      meta: {
        title: '管理员列表',
        icon: ''
      }
    }, {
      path: 'promiseRules',
      component: function component() {
        return import('@/views/systemSetting/administratorAuthority/permissionRules');
      },
      name: 'promiseRules',
      meta: {
        title: '权限规则',
        icon: ''
      }
    }]
  }, {
    path: 'logistics',
    name: 'Logistics',
    component: function component() {
      return import('@/views/maintain/logistics');
    },
    meta: {
      title: '物流设置',
      icon: 'clipboard'
    },
    children: [{
      path: 'companyList',
      component: function component() {
        return import('@/views/maintain/logistics/companyList/index');
      },
      name: 'companyList',
      meta: {
        title: '物流公司',
        icon: ''
      }
    }]
  }, {
    path: 'maintain',
    name: 'Maintain',
    meta: {
      title: '系统维护',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return import('@/views/maintain');
    },
    children: [{
      path: 'devconfiguration',
      name: 'devconfiguration',
      component: function component() {
        return import('@/views/maintain');
      },
      meta: {
        title: '开发配置',
        icon: 'clipboard'
      },
      children: [{
        path: 'configCategory',
        name: 'configCategory',
        component: function component() {
          return import('@/views/maintain/devconfig/configCategroy');
        },
        meta: {
          title: '配置分类',
          icon: 'clipboard'
        }
      }, {
        path: 'combineddata',
        name: 'combineddata',
        component: function component() {
          return import('@/views/maintain/devconfig/combinedData');
        },
        meta: {
          title: '组合数据',
          icon: 'clipboard'
        }
      }, {
        path: 'formConfig',
        name: 'formConfig',
        component: function component() {
          return import('@/views/maintain/formConfig/index');
        },
        meta: {
          title: '表单配置',
          icon: 'clipboard'
        }
      }]
    }, {
      path: 'authAuthorization',
      name: 'authAuthorization',
      component: function component() {
        return import('@/views/maintain/authAuthorization');
      },
      meta: {
        title: '授权',
        icon: 'clipboard'
      },
      hidden: false
    }, {
      path: 'schedule',
      name: 'schedule',
      component: function component() {
        return import('@/views/maintain');
      },
      meta: {
        title: '定时任务管理',
        icon: 'clipboard',
        roles: ['admin']
      },
      children: [{
        path: 'list',
        component: function component() {
          return import('@/views/maintain/schedule/list');
        },
        name: 'list',
        meta: {
          title: '定时任务',
          icon: ''
        }
      }, {
        path: 'logList',
        component: function component() {
          return import('@/views/maintain/schedule/logList');
        },
        name: 'logList',
        meta: {
          title: '定时任务日志',
          icon: ''
        }
      }]
    }, {
      path: 'sensitiveLog',
      name: 'sensitiveLog',
      component: function component() {
        return import('@/views/maintain/sensitiveList');
      },
      meta: {
        title: '敏感操作日志',
        icon: 'clipboard'
      },
      hidden: false
    }, {
      path: 'mqLog',
      name: 'mqLog',
      component: function component() {
        return import('@/views/maintain/mqLog');
      },
      meta: {
        title: 'MQ日志',
        icon: 'clipboard'
      },
      hidden: false
    }, {
      path: 'user',
      name: 'user',
      component: function component() {
        return import('@/views/maintain/user');
      },
      meta: {
        title: '个人中心',
        icon: 'clipboard'
      },
      hidden: true
    }]
  }, {
    path: 'onePass',
    name: 'OnePass',
    meta: {
      title: '一号通',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return import('@/views/onePass');
    },
    children: [{
      path: 'index',
      name: 'onePass',
      component: function component() {
        return import('@/views/onePass/smsConfig');
      },
      meta: {
        title: '一号通',
        icon: 'clipboard'
      }
    }, {
      path: 'pay',
      component: function component() {
        return import('@/views/onePass/smsPay');
      },
      name: 'SmsPay',
      meta: {
        title: '短信购买',
        noCache: true,
        activeMenu: "/operation/onePass/index"
      }
    }, {
      path: 'template',
      component: function component() {
        return import('@/views/onePass/smsTemplate');
      },
      name: 'SmsTemplate',
      meta: {
        title: '短信模板',
        noCache: true,
        activeMenu: "/operation/onePass/index"
      }
    }]
  }, {
    path: 'application',
    name: 'Application',
    meta: {
      title: '应用设置',
      noCache: true
    },
    redirect: 'noRedirect',
    component: function component() {
      return import('@/views/application/wxAccount');
    },
    children: [{
      path: 'publicAccount',
      name: 'publicAccount',
      component: function component() {
        return import('@/views/application/wxAccount');
      },
      meta: {
        title: '公众号',
        icon: 'clipboard',
        roles: ['admin']
      },
      children: [{
        path: 'wxMenus',
        component: function component() {
          return import('@/views/application/wxAccount/wxMenus');
        },
        name: 'wxMenus',
        meta: {
          title: '微信菜单',
          icon: ''
        }
      }, {
        path: 'wxReply',
        component: function component() {
          return import('@/views/application/wxAccount/reply/index');
        },
        name: 'wxReply',
        meta: {
          title: '自动回复',
          icon: ''
        },
        children: [{
          path: 'follow',
          component: function component() {
            return import('@/views/application/wxAccount/reply/follow');
          },
          name: 'wxFollow',
          meta: {
            title: '微信关注回复',
            icon: ''
          }
        }, {
          path: 'keyword',
          component: function component() {
            return import('@/views/application/wxAccount/reply/keyword');
          },
          name: 'wxKeyword',
          meta: {
            title: '关键字回复',
            icon: ''
          }
        }, {
          path: 'replyIndex',
          component: function component() {
            return import('@/views/application/wxAccount/reply/follow');
          },
          name: 'wxReplyIndex',
          meta: {
            title: '无效关键词回复',
            icon: ''
          }
        }, {
          path: 'keyword/save/:id?',
          name: 'wechatKeywordAdd',
          meta: {
            title: '关键字添加',
            noCache: true,
            activeMenu: "/application/publicAccount/wxReply/keyword"
          },
          hidden: true,
          component: function component() {
            return import('@/views/application/wxAccount/reply/follow');
          }
        }]
      }]
    }, {
      path: 'publicRoutine',
      name: 'PublicRoutine',
      component: function component() {
        return import('@/views/application/routine');
      },
      meta: {
        title: '小程序',
        icon: 'clipboard',
        roles: ['admin'],
        hidden: true
      },
      children: [{
        path: 'download',
        component: function component() {
          return import('@/views/application/routine/download/index.vue');
        },
        name: 'download',
        meta: {
          title: '小程序下载',
          icon: ''
        }
      }]
    }]
  }, {
    path: 'cityPage',
    name: 'cityPage',
    component: function component() {
      return import('@/views/systemSetting/cityPage');
    },
    meta: {
      title: '城市数据',
      icon: 'clipboard'
    }
  }]
};
export default operationRouter; //collate