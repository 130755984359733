import "core-js/modules/es6.array.copy-within";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.from";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.array.of";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.species";
import "core-js/modules/es6.date.to-primitive";
import "core-js/modules/es6.function.has-instance";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.map";
import "core-js/modules/es6.math.acosh";
import "core-js/modules/es6.math.asinh";
import "core-js/modules/es6.math.atanh";
import "core-js/modules/es6.math.cbrt";
import "core-js/modules/es6.math.clz32";
import "core-js/modules/es6.math.cosh";
import "core-js/modules/es6.math.expm1";
import "core-js/modules/es6.math.fround";
import "core-js/modules/es6.math.hypot";
import "core-js/modules/es6.math.imul";
import "core-js/modules/es6.math.log1p";
import "core-js/modules/es6.math.log10";
import "core-js/modules/es6.math.log2";
import "core-js/modules/es6.math.sign";
import "core-js/modules/es6.math.sinh";
import "core-js/modules/es6.math.tanh";
import "core-js/modules/es6.math.trunc";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.epsilon";
import "core-js/modules/es6.number.is-finite";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.number.is-safe-integer";
import "core-js/modules/es6.number.max-safe-integer";
import "core-js/modules/es6.number.min-safe-integer";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es7.object.define-getter";
import "core-js/modules/es7.object.define-setter";
import "core-js/modules/es7.object.entries";
import "core-js/modules/es6.object.freeze";
import "core-js/modules/es6.object.get-own-property-descriptor";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.get-own-property-names";
import "core-js/modules/es6.object.get-prototype-of";
import "core-js/modules/es7.object.lookup-getter";
import "core-js/modules/es7.object.lookup-setter";
import "core-js/modules/es6.object.prevent-extensions";
import "core-js/modules/es6.object.is";
import "core-js/modules/es6.object.is-frozen";
import "core-js/modules/es6.object.is-sealed";
import "core-js/modules/es6.object.is-extensible";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.seal";
import "core-js/modules/es6.object.set-prototype-of";
import "core-js/modules/es7.object.values";
import "core-js/modules/es6.promise";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.reflect.apply";
import "core-js/modules/es6.reflect.construct";
import "core-js/modules/es6.reflect.define-property";
import "core-js/modules/es6.reflect.delete-property";
import "core-js/modules/es6.reflect.get";
import "core-js/modules/es6.reflect.get-own-property-descriptor";
import "core-js/modules/es6.reflect.get-prototype-of";
import "core-js/modules/es6.reflect.has";
import "core-js/modules/es6.reflect.is-extensible";
import "core-js/modules/es6.reflect.own-keys";
import "core-js/modules/es6.reflect.prevent-extensions";
import "core-js/modules/es6.reflect.set";
import "core-js/modules/es6.reflect.set-prototype-of";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.flags";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.set";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.string.anchor";
import "core-js/modules/es6.string.big";
import "core-js/modules/es6.string.blink";
import "core-js/modules/es6.string.bold";
import "core-js/modules/es6.string.code-point-at";
import "core-js/modules/es6.string.ends-with";
import "core-js/modules/es6.string.fixed";
import "core-js/modules/es6.string.fontcolor";
import "core-js/modules/es6.string.fontsize";
import "core-js/modules/es6.string.from-code-point";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.string.italics";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.string.link";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es7.string.pad-end";
import "core-js/modules/es6.string.raw";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.string.small";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.strike";
import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.string.sup";
import "core-js/modules/es6.typed.array-buffer";
import "core-js/modules/es6.typed.int8-array";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.typed.uint8-clamped-array";
import "core-js/modules/es6.typed.int16-array";
import "core-js/modules/es6.typed.uint16-array";
import "core-js/modules/es6.typed.int32-array";
import "core-js/modules/es6.typed.uint32-array";
import "core-js/modules/es6.typed.float32-array";
import "core-js/modules/es6.typed.float64-array";
import "core-js/modules/es6.weak-map";
import "core-js/modules/es6.weak-set";
import "core-js/modules/web.timers";
import "core-js/modules/web.immediate";
import "core-js/modules/web.dom.iterable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import Element from 'element-ui';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import './styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import '@/assets/iconfont/iconfont-weapp-icon.css';
import '@/assets/iconfont/iconfont.css';
import 'swiper/dist/css/swiper.css';
import 'vue-ydui/dist/ydui.base.css';
// import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/parsing";
// 懒加载
import VueLazyload from 'vue-lazyload';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Cookies from 'js-cookie';
import Debounce from './libs/debounce.js'; //防抖自定义指令
Vue.config.devtools = true;
import App from './App';
import store from './store';
import router from './router';
import base from './components/base/index'; // 公共组件
import uploadPicture from './components/uploadFrom';
import goodListFrom from './components/goodList/goodListFrom';
import couponFrom from './components/couponList/couponFrom';
import articleFrom from './components/articleList/articleFrom';
import { loadScriptQueue } from '@/components/FormGenerator/utils/loadScript';
import './icons'; // icon
import './permission'; // permission control
import './utils/error-log'; // error integralLog
import * as filters from './filters'; // global filters
import { parseQuery } from '@/utils';
import plugins from './plugins';
import directive from './directive'; //directive
import libs from './libs/index.js'; // 全局函数
import './utils/directives.js';
import './utils/emoji';
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/imgs/no.png'),
  loading: require('./assets/imgs/moren.jpg'),
  attempt: 1,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
});
Vue.use(uploadPicture);
Vue.use(goodListFrom);
Vue.use(articleFrom);
Vue.use(couponFrom);
Vue.use(VueAwesomeSwiper);
Vue.use(plugins);
Vue.use(directive);
Vue.use(libs);
Vue.use(base);
var cookieName = 'VCONSOLE';
var query = parseQuery();
var urlSpread = query['spread'];
var vconsole = query[cookieName.toLowerCase()];
var md5Sole = 'b14d1e9baeced9bb7525ab19ee35f2d2'; // MD5 加密开启vconsole模式
var md5UnSole = '3dca2162c4e101b7656793a1af20295c'; // MD5 加密关闭vconsole模式

if (vconsole !== undefined) {
  if (vconsole === md5UnSole && Cookies.has(cookieName)) Cookies.remove(cookieName);
} else vconsole = Cookies.get(cookieName);
if (vconsole !== undefined && vconsole === md5Sole) {
  Cookies.set(cookieName, md5Sole, 3600);
  var module = function module() {
    return import('vconsole');
  };
  module().then(function (Module) {
    new Module.default();
  });
}
// 自定义实现String 类型的replaceAll方法
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, 'gm'), s2);
};
// Vue.prototype.$modalCoupon = modalCoupon
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
import emojiRegex from 'emoji-regex'; // npm install emoji-regex
Vue.directive('clearEmoij', {
  bind: function bind(el, binding, vnode, oldVnode) {
    var regex = emojiRegex();
    var obj = el.querySelectorAll('.el-input__inner,.el-textarea__inner')[0];
    var _zclearNoNum = function zclearNoNum(e) {
      if (e.target.composing) return;
      var match = regex.exec(obj.value); // 也可以直接用正则表达式判断
      if (match) {
        /* for (let i=0;i<match.length;i++) {
          obj.value = obj.value.replace(match[i],"");
        }*/
        obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
        _zclearNoNum(e);
      }
      // 触发v-model的更新
      obj.dispatchEvent(new Event('input'));
    };
    var zblur = function zblur(e) {
      _zclearNoNum(e);
      setTimeout(function () {
        var match = regex.exec(obj.value);
        if (match) {
          obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
          _zclearNoNum(e);
        }
        // 触发v-model的更新
        obj.dispatchEvent(new Event('change'));
      }, 50);
    };
    obj.onkeyup = _zclearNoNum;
    obj.onblur = zblur;
    function onCompositionStart(e) {
      e.target.composing = true;
    }
    function onCompositionEnd(e) {
      console.log('按回车将字输入', e.target.value);
      e.target.composing = false;
      obj.dispatchEvent(new Event('change'));
      setTimeout(function () {
        if (obj.value) {
          obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
          _zclearNoNum(e);
        }
        // 触发v-model的更新
        obj.dispatchEvent(new Event('change'));
      }, 50);
    }
    obj.addEventListener('compositionstart', onCompositionStart);
    obj.addEventListener('compositionend', onCompositionEnd);
  },
  update: function update(el, binding, vnode, oldVnode) {}
});
Vue.use(Element, {
  size: 'small',
  // set element-ui default size
  zIndex: 3000
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
var $previewApp = document.getElementById('previewApp');
var childAttrs = {
  file: '',
  dialog: ' width="600px" class="dialog-width" v-if="visible" :visible.sync="visible" :modal-append-to-body="false" '
};
window.addEventListener('message', init, false);
function buildLinks(links) {
  var strs = '';
  links.forEach(function (url) {
    strs += "<link href=\"".concat(url, "\" rel=\"stylesheet\">");
  });
  return strs;
}
// 修改 el-dialog 默认点击遮照为不关闭\全局设置点击遮罩层不关闭 Dialog
Element.Dialog.props.closeOnClickModal.default = false;
//Element-UI抽屉Drawer，阻止点击遮罩层关闭
Element.Drawer.props.wrapperClosable.default = false;
function init(event) {
  if (event.data.type === 'refreshFrame') {
    var code = event.data.data;
    var attrs = childAttrs[code.generateConf.type];
    var links = '';
    if (Array.isArray(code.links) && code.links.length > 0) {
      links = buildLinks(code.links);
    }
    $previewApp.innerHTML = "".concat(links, "<style>").concat(code.css, "</style><div id=\"app\"></div>");
    if (Array.isArray(code.scripts) && code.scripts.length > 0) {
      loadScriptQueue(code.scripts, function () {
        newVue(attrs, code.js, code.html);
      });
    } else {
      newVue(attrs, code.js, code.html);
    }
  }
}
function newVue(attrs, main, html) {
  // eslint-disable-next-line no-eval
  main = eval("(".concat(main, ")"));
  main.template = "<div>".concat(html, "</div>");
  new Vue({
    components: {
      child: main
    },
    data: function data() {
      return {
        visible: true
      };
    },
    template: "<div><child ".concat(attrs, "/></div>")
  }).$mount('#app');
}
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, 'gm'), s2);
};

/**
 * 防抖 防止重复点击
 * 传参：v-debounceClick="() =>{handleFun(arg)}"
 * 不传参:v-debounceClick="handleFun"
 * delayTime:延迟的时间,只执行最后一次
 */
Vue.directive('debounceClick', {
  bind: function bind(el, binding, vnode, oldvnode) {},
  inserted: function inserted(el, binding) {
    var delayTime = el.getAttribute('delay-time') || 500;
    el.onclick = Debounce(function () {
      binding.value();
    }, delayTime);
  }
});
console.customLog = function (label, message) {
  console.log("".concat(label, ": ").concat(message));
};
var version = require('../package.json').version;
var versionStorage = localStorage.getItem('version');
console.customLog('最新版本', 'v' + version);
console.customLog('缓存版本', 'v' + versionStorage);
if (version != versionStorage) {
  localStorage.clear();
  localStorage.setItem('version', version);
  console.customLog('版本不一致，清除缓存中');

  //location.reload() 方法用来刷新当前页面。该方法只有一个参数，当值为 true 时，将强制浏览器从服务器加载页面资源，
  //当值为 false 或者未传参时，浏览器则可能从缓存中读取页面。
  setTimeout(function () {
    window.location.reload(true);
  }, 1000);
}
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});