import Vue from 'vue';
Vue.directive('forbid-special-chars', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;

      // Check if the composition (Chinese character input) is ongoing
      if (!event.isComposing) {
        // Regular expression to exclude emojis and special characters
        var regRule = /[\uD800-\uDFFF\u2600-\u2B55\u23CF\u23E9-\u23F3\u231A\u231B\u23E3\u3030\u25AB\u25FE!@#$%^&*(),.?":{}|<> ]/g;
        if (regRule.test(inputValue)) {
          // If input contains emojis or special characters, remove them
          event.target.value = inputValue.replace(regRule, '');
        }
      }
    };

    // Listen for input events
    el.addEventListener('compositionstart', el.handleInput);
    el.addEventListener('input', el.handleInput);
    el.addEventListener('compositionend', el.handleInput);
  },
  unbind: function unbind(el) {
    // Remove the event listeners when the directive is unbound
    el.removeEventListener('compositionstart', el.handleInput);
    el.removeEventListener('input', el.handleInput);
    el.removeEventListener('compositionend', el.handleInput);
  }
});
Vue.directive('only-number', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则只允许输入数字
      var regRule = /^\d*$/;
      if (!regRule.test(inputValue)) {
        // 如果输入值不是纯数字，则清空输入框
        event.target.value = inputValue.replace(/[^\d]/g, '');
      }
    };

    // 监听输入事件
    el.addEventListener('input', el.handleInput);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});
Vue.directive('only-alphanumeric', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则只允许输入数字和字母
      var regRule = /[^a-zA-Z0-9]/g;
      console.log(regRule.test(inputValue));
      if (regRule.test(inputValue)) {
        // 如果输入值包含非数字和非字母字符，则替换掉这些字符
        event.target.value = inputValue.replace(regRule, '');
      }
    };
    // 监听输入事件
    el.addEventListener('input', el.handleInput);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});
Vue.directive('only-chinesepencent', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则只允许输入最多保留两位小数的百分数并且是汉字
      var regRule = /^[A-Za-z]+|\d+\.\d{3,}$/;
      if (regRule.test(inputValue)) {
        // 如果输入值包含非汉字字符，则替换掉
        event.target.value = inputValue.replace(regRule, '');
      }
    };

    // 监听输入事件
    el.addEventListener('input', el.handleInput);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});
Vue.directive('only-point-number', {
  bind: function bind(el) {
    el.handleInput = function (event) {
      var inputValue = event.target.value;
      // 正则规则允许输入保留小数的数字
      var regRule = /^[A-Za-z\u4e00-\u9fa5]+$/;
      if (regRule.test(inputValue)) {
        // 如果输入值包含非数字字符，则替换掉非数字字符
        event.target.value = inputValue.replace(regRule, '');
      }
    };

    // 监听输入事件
    el.addEventListener('input', el.handleInput);
  },
  unbind: function unbind(el) {
    // 移除监听器
    el.removeEventListener('input', el.handleInput);
  }
});