import store from '@/store';
export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var super_admin = 'admin';
    var roles = store.state.user.name;
    if (value && value instanceof Array && value.length > 0) {
      var roleFlag = value;
      var hasRole = roles.some(function (role) {
        return super_admin === role || roleFlag.includes(role);
      });
      if (!hasRole) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("\u8BF7\u8BBE\u7F6E\u89D2\u8272\u6743\u9650\u6807\u7B7E\u503C\"");
    }
  }
};