function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { canSendListApi } from '@/api/marketing';
import { productCouponListApi } from '@/api/product';
export default {
  name: 'CouponList',
  props: {
    handle: {
      type: String,
      default: ''
    },
    couponData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    keyNum: {
      type: Number,
      default: 0
    },
    userIds: {
      type: String,
      default: ''
    },
    userType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        keywords: ''
      },
      multipleSelection: [],
      multipleSelectionAll: [],
      idKey: 'id',
      nextPageFlag: false,
      attr: []
    };
  },
  watch: {
    keyNum: {
      deep: true,
      handler: function handler(val) {
        this.multipleSelectionAll = _toConsumableArray(this.couponData);
        this.getList();
      }
    }
  },
  mounted: function mounted() {
    this.multipleSelectionAll = _toConsumableArray(this.couponData);
    this.tableFrom.page = 1;
    this.getList();
  },
  methods: {
    close: function close() {
      this.multipleSelection = [];
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this = this;
      this.multipleSelection = val;
      setTimeout(function () {
        _this.$selfUtil.changePageCoreRecordData(_this.multipleSelectionAll, _this.multipleSelection, _this.tableData.data, function (e) {
          _this.multipleSelectionAll = e;
        });
      }, 50);
    },
    // 设置选中的方法
    setSelectRow: function setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      var idKey = this.idKey;
      var selectAllIds = [];
      this.multipleSelectionAll.forEach(function (row) {
        selectAllIds.push(row[idKey]);
      });
      this.$refs.table.clearSelection();
      for (var i = 0; i < this.tableData.data.length; i++) {
        if (selectAllIds.indexOf(this.tableData.data[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.tableData.data[i], true);
        }
      }
    },
    ok: function ok() {
      if (this.multipleSelection.length > 0) {
        var couponList = _toConsumableArray(this.multipleSelectionAll);
        this.$emit('getCouponId', couponList);
        this.close();
      } else {
        this.$message.warning('请先选择优惠劵');
      }
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      canSendListApi(this.tableFrom).then(function (res) {
        _this2.visibleCoupon = true;
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.$nextTick(function () {
          this.setSelectRow(); //调用跨页选中方法
        });
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var _this3 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this3.multipleSelectionAll = e;
      });
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;
      this.$selfUtil.changePageCoreRecordData(this.multipleSelectionAll, this.multipleSelection, this.tableData.data, function (e) {
        _this4.multipleSelectionAll = e;
      });
      this.tableFrom.limit = val;
      this.getList();
    },
    // 发送
    sendGrant: function sendGrant(id) {
      var _this5 = this;
      this.$modalSure('发送优惠劵吗').then(function () {
        couponUserApi({
          couponId: id,
          uid: _this5.userIds
        }).then(function () {
          _this5.$message.success('发送成功');
          _this5.getList();
        });
      });
    }
  }
};