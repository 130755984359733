// 请求接口地址 如果没有配置自动获取当前网址路径
var VUE_APP_API_URL = process.env.VUE_APP_BASE_API || "".concat(location.origin, "/api/");
var VUE_APP_title = process.env.VUE_APP_title || '';
module.exports = {
  // 路由前缀
  // roterPre: '/admin',
  // 接口请求地址
  apiBaseURL: VUE_APP_API_URL,
  title: VUE_APP_title,
  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   * 是否显示设置右侧面板
   */
  showSettings: true,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   * 是否使用tagsView
   */
  tagsView: true,
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   * 是否固定header
   */
  fixedHeader: true,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   * 是否在侧边栏中显示logo
   */
  sidebarLogo: true,
  /**
   * @type {boolean} true | false
   * @description Whether to turn on top bar navigation
   * 是否开启顶栏导航
   */
  topNav: false,
  /**
   * @type {boolean} true | false
   * @description Whether to navigation icon
   * 是否显示导航icon
   */
  navIcon: true,
  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
};