//
//
//
//
//

// import { copyrightInfoApi } from '@/api/authInformation';
// import { checkPermi } from '@/utils/permission'; // 权限判断函数

export default {
  name: 'i-copyright',
  data: function data() {
    return {
      // copyright: '',
      copyrightNew: ''
    };
  },
  mounted: function mounted() {
    this.copyrightNew = "Copyright \xA9 2023 ".concat(process.env.VUE_APP_title);

    // if (checkPermi(['platform:copyright:get:info'])) this.getVersion();
  },
  methods: {
    // getVersion() {
    // 	copyrightInfoApi().then((res) => {
    // 		const data = res || {};
    // 		console.log(data,'=====data===')
    // 		this.copyrightNew = data.companyName ? data.companyName : this.copyright;
    // 	});
    // },
  }
};