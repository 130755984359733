import defaultSettings from '@/settings';
import Cache from '@/plugins/cache';
var showSettings = defaultSettings.showSettings,
  tagsView = defaultSettings.tagsView,
  fixedHeader = defaultSettings.fixedHeader,
  sidebarLogo = defaultSettings.sidebarLogo,
  topNav = defaultSettings.topNav,
  sideTheme = defaultSettings.sideTheme,
  navIcon = defaultSettings.navIcon;
var storageSetting = Cache.local.has('layout-setting') ? Cache.local.getJSON('layout-setting') : '';
var state = {
  theme: storageSetting.theme || '#409EFF',
  sideTheme: storageSetting.sideTheme || sideTheme,
  topNav: storageSetting.topNav === undefined ? topNav : storageSetting.topNav,
  showSettings: showSettings,
  tagsView: storageSetting.tagsView === undefined ? tagsView : storageSetting.tagsView,
  fixedHeader: storageSetting.fixedHeader === undefined ? fixedHeader : storageSetting.fixedHeader,
  sidebarLogo: storageSetting.sidebarLogo === undefined ? sidebarLogo : storageSetting.sidebarLogo,
  navIcon: storageSetting.navIcon === undefined ? navIcon : storageSetting.navIcon,
  frontDomain: localStorage.getItem('frontDomain') || '',
  //移动端域名
  mediaDomain: localStorage.getItem('mediaDomain') || '' //图片域名
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  SET_FrontDomain: function SET_FrontDomain(state, frontDomain) {
    state.frontDomain = frontDomain;
    localStorage.setItem('frontDomain', frontDomain);
    if (!frontDomain) localStorage.removeItem('frontDomain');
  },
  SET_mediaDomain: function SET_mediaDomain(state, mediaDomain) {
    state.mediaDomain = mediaDomain;
    if (!mediaDomain) localStorage.removeItem('mediaDomain');
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};