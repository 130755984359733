/**
 *优惠券 类型
 */
export function couponCategory(status) {
  var statusMap = {
    1: '店铺',
    2: '商品',
    3: '通用',
    4: '品类',
    5: '品牌',
    6: '跨店'
  };
  return statusMap[status];
}

/**
 *抽奖 类型
 */
export function prizeDraw(status) {
  var statusMap = {
    1: '优惠券金额',
    2: '谢谢惠顾'
  };
  return statusMap[status];
}