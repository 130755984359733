//
//
//
//
//
//
//
//
//
//
//
//
//

import * as systemConfigApi from '@/api/systemConfig.js';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '',
      logo: '',
      logoSmall: ''
    };
  },
  mounted: function mounted() {
    this.getLogo();
    this.getSquareLogo();
  },
  methods: {
    getLogo: function getLogo() {
      var _this = this;
      systemConfigApi.configGetUniq({
        key: 'site_logo_lefttop'
      }).then(function (data) {
        _this.logo = data;
      });
    },
    getSquareLogo: function getSquareLogo() {
      var _this2 = this;
      systemConfigApi.configGetUniq({
        key: 'site_logo_square'
      }).then(function (data) {
        _this2.logoSmall = data;
      });
    }
  }
};