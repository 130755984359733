import Layout from '@/layout';
var financeRouter = {
  path: '/finance',
  component: Layout,
  redirect: '/finance',
  name: 'Financial',
  meta: {
    title: '财务',
    icon: 'clipboard'
  },
  children: [{
    path: 'statement',
    name: 'FinanceStatement',
    meta: {
      title: '账单管理',
      noCache: true
    },
    component: function component() {
      return import('@/views/finance/statement/index');
    }
  }, {
    path: 'closingSetting',
    component: function component() {
      return import('@/views/finance/setting/index');
    },
    name: 'ClosingSetting',
    meta: {
      title: '商户结算设置',
      icon: ''
    }
  }, {
    path: 'closing',
    component: function component() {
      return import('@/views/finance/closing/index');
    },
    name: 'FinancialClosing',
    meta: {
      title: '结算管理',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'merchantClosing',
      component: function component() {
        return import('@/views/finance/closing/merchantClosing/index');
      },
      name: 'MerchantClosing',
      meta: {
        title: '商户结算',
        icon: ''
      }
    }, {
      path: 'userClosing',
      component: function component() {
        return import('@/views/finance/closing/userClosing/index');
      },
      name: 'UserClosing',
      meta: {
        title: '用户结算',
        icon: ''
      }
    }]
  }, {
    path: 'charge',
    component: function component() {
      return import('@/views/finance/charge/index');
    },
    name: 'FinanceCharge',
    meta: {
      title: '充值记录',
      icon: ''
    }
  }, {
    path: 'bill',
    component: function component() {
      return import('@/views/finance/bill/index');
    },
    name: 'Bill',
    meta: {
      title: '平台对账单管理',
      icon: ''
    }
  }, {
    path: 'journalAccount',
    component: function component() {
      return import('@/views/finance/journalAccount/index');
    },
    name: 'FinanceJournalAccount',
    meta: {
      title: '流水管理',
      icon: ''
    },
    alwaysShow: true,
    children: [{
      path: 'capitalFlow',
      name: 'FinanceCapitalFlow',
      meta: {
        title: '资金流水',
        noCache: true
      },
      component: function component() {
        return import('@/views/finance/journalAccount/capitalFlow/index');
      }
    }, {
      path: 'summaryCapitalFlow',
      component: function component() {
        return import('@/views/finance/journalAccount/summaryCapitalFlow/index');
      },
      name: 'UserClosing',
      meta: {
        title: '流水汇总',
        icon: ''
      }
    }]
  }]
};
export default financeRouter;