import request from '@/utils/request';

/**
 * 商户分类 列表
 */
export function merchantCategoryListApi(params) {
  return request({
    url: '/admin/platform/merchant/category/list',
    method: 'get',
    params: params
  });
}

/**
 * 商户分类 全部列表
 */
export function merchantCategoryAllListApi() {
  return request({
    url: '/admin/platform/merchant/category/all/list',
    method: 'get'
  });
}

/**
 * 商户分类 添加
 */
export function merchantCategoryAddApi(data) {
  return request({
    url: '/admin/platform/merchant/category/add',
    method: 'post',
    data: data
  });
}

/**
 * 商户分类 编辑
 */
export function merchantCategoryUpdateApi(data) {
  return request({
    url: '/admin/platform/merchant/category/update',
    method: 'post',
    data: data
  });
}

/**
 * 商户分类 删除
 */
export function merchantCategoryDeleteApi(id) {
  return request({
    url: "/admin/platform/merchant/category/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 店铺类型 列表
 */
export function merchantTypeListApi(params) {
  return request({
    url: '/admin/platform/merchant/type/list',
    method: 'get',
    params: params
  });
}

/**
 * 店铺类型 全部列表
 */
export function merchantTypeAllListApi() {
  return request({
    url: '/admin/platform/merchant/type/all/list',
    method: 'get'
  });
}

/**
 * 店铺类型 添加
 */
export function merchantTypeAddApi(data) {
  return request({
    url: '/admin/platform/merchant/type/add',
    method: 'post',
    data: data
  });
}

/**
 * 店铺类型 编辑
 */
export function merchantTypeUpdateApi(data) {
  return request({
    url: '/admin/platform/merchant/type/update',
    method: 'post',
    data: data
  });
}

/**
 * 店铺类型 删除
 */
export function merchantTypeDeleteApi(id) {
  return request({
    url: "/admin/platform/merchant/type/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 列表
 */
export function merchantListApi(params) {
  return request({
    url: '/admin/platform/merchant/list',
    method: 'get',
    params: params
  });
}

/**
 * 商户 添加
 */
export function merchantAddApi(data) {
  return request({
    url: '/admin/platform/merchant/add',
    method: 'post',
    data: data
  });
}

/**
 * 商户 编辑
 */
export function merchantUpdateApi(data) {
  return request({
    url: '/admin/platform/merchant/update',
    method: 'post',
    data: data
  });
}

/**
 * 商户 关闭
 */
export function merchantCloseApi(id) {
  return request({
    url: "/admin/platform/merchant/close/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 详情
 */
export function merchantDetailApi(id) {
  return request({
    url: "/admin/platform/merchant/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 商户 开启
 */
export function merchantOpenApi(id) {
  return request({
    url: "/admin/platform/merchant/open/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 推荐开关
 */
export function merchantSwitchApi(id) {
  return request({
    url: "/admin/platform/merchant/recommend/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 商户供应商 推荐开关
 */
export function menuSupplierApi(id) {
  return request({
    url: "/admin/merchant/supplier/open/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 修改复制商品数量
 */
export function merchantCopyNumApi(data) {
  return request({
    url: "/admin/platform/merchant/update/copy/product/num",
    method: 'post',
    data: data
  });
}

/**
 * 商户 修改商户手机号
 */
export function merchantupdatePhoneApi(data) {
  return request({
    url: "/admin/platform/merchant/update/phone",
    method: 'post',
    data: data
  });
}

/**
 * 商户 权限规则菜单列表
 * @param pram
 */
export function menuMerListApi(params) {
  var data = {
    menuType: params.menuType,
    //菜单类型:M-目录，C-菜单，A-按钮
    name: params.name //菜单名称
  };
  return request({
    url: "/admin/platform/merchant/menu/list",
    method: 'get',
    params: data
  });
}

/**
 * 商户 权限规则新增菜单
 * @param data
 */
export function menuMerAdd(data) {
  var systemMenuRequest = data;
  return request({
    url: "/admin/platform/merchant/menu/add",
    method: 'post',
    data: systemMenuRequest
  });
}

/**
 * 商户 权限规则删除菜单
 * @param data
 */
export function menuMerDelete(id) {
  return request({
    url: "/admin/platform/merchant/menu/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 权限规则菜单详情
 * @param data
 */
export function menuMerInfo(id) {
  return request({
    url: "/admin/platform/merchant/menu/info/".concat(id),
    method: 'get'
  });
}

/**
 * 商户 权限规则菜单修改
 * @param data
 */
export function menuMerUpdate(data) {
  var systemMenuRequest = data;
  return request({
    url: "/admin/platform/merchant/menu/update",
    method: 'post',
    data: systemMenuRequest
  });
}

/**
 * 权限规则修改菜单显示状态
 * @param data
 */
export function menuMerUpdateShowStatus(params) {
  return request({
    url: "/admin/platform/merchant/menu/update/show/".concat(params.id),
    method: 'post'
  });
}

/**
 * 商户入驻分页列表
 * @param data
 */
export function merApplyListApi(params) {
  return request({
    url: "/admin/platform/merchant/apply/list",
    method: 'get',
    params: params
  });
}

/**
 * 商户入驻审核
 * @param data
 */
export function merApplyAuditApi(data) {
  return request({
    url: "/admin/platform/merchant/apply/audit",
    method: 'post',
    data: data
  });
}

/**
 * 商户入驻备注
 * @param data
 */
export function merApplyRemarkApi(data) {
  return request({
    url: "/admin/platform/merchant/apply/remark",
    method: 'post',
    data: data
  });
}

/**
 * 商户分页列表表头数量
 * @param data
 */
export function merHeaderNumApi(params) {
  return request({
    url: "/admin/platform/merchant/list/header/num",
    method: 'get',
    params: params
  });
}

/**
 * 重置商户密码
 * @param data
 */
export function merRsetPasswordApi(id) {
  return request({
    url: "/admin/platform/merchant/reset/password/".concat(id),
    method: 'post'
  });
}

/**
 * 可用分类商户列表
 * @param data
 */
export function merCategoryListApi() {
  return request({
    url: "/admin/platform/merchant/use/category/list",
    method: 'get'
  });
}

/**
 * 特色专区列表
 * @param data
 */
export function platformThemePage(data) {
  return request({
    url: "/admin/platform/theme/page",
    method: 'post',
    data: data
  });
}

/**
 * 特色专区装修列表
 * @param data
 */
export function platformThemePageGet(params) {
  return request({
    url: "/admin/platform/theme/page/get",
    method: 'GET',
    params: params
  });
}
/**
 * 特色专区新增
 * @param data
 */
export function platformThemeAdd(data) {
  return request({
    url: "/admin/platform/theme/add",
    method: 'post',
    data: data
  });
}

/**
 * 特色专区新增
 * @param data
 */
export function platformThemeGetByid(id) {
  return request({
    url: "/admin/platform/theme/get/byid/".concat(id),
    method: 'get'
  });
}

/**
 * 特色专区删除
 * @param data
 */
export function platformThemeDelete(id) {
  return request({
    url: "/admin/platform/theme/delete/".concat(id),
    method: 'delete'
  });
}
/**
 * 特色专区修改
 * @param data
 */
export function platformThemeUpdeta(data) {
  return request({
    url: "/admin/platform/theme/update",
    method: 'put',
    data: data
  });
}

/**
 * 特色专区菜单--列表
 * @param data
 */
export function platformMenusPage(data) {
  return request({
    url: "/admin/platform/theme/menus/page",
    method: 'post',
    data: data
  });
}

/**
 * 特色专区菜单--新增
 * @param data
 */
export function platformMenusSave(data) {
  return request({
    url: "/admin/platform/theme/menus/save",
    method: 'post',
    data: data
  });
}

/**
 * 特色专区菜单---修改
 * @param data
 */
export function platformMenusUpdate(data) {
  return request({
    url: "/admin/platform/theme/menus/update",
    method: 'put',
    data: data
  });
}

/**
 * 特色专区菜单---删除
 * @param data
 */
export function platformMenusDelete(id) {
  return request({
    url: "/admin/platform/theme/menus/delete/".concat(id),
    method: 'delete'
  });
}

/**
 * 特色专区菜单--查询
 * @param data
 */
export function platformMenusByid(id) {
  return request({
    url: "/admin/platform/theme/menus/get/byid/".concat(id),
    method: 'get'
  });
}

/**
 * 特色专区--查询列表
 * @param data
 */
export function platformThemeList(data) {
  return request({
    url: "/admin/platform/theme/list",
    method: 'post',
    data: data
  });
}

/**
 * 商户登录--获取
 * @param data
 */
export function platformUrlLogin(merId) {
  return request({
    url: "/admin/platform/url/login/".concat(merId),
    method: 'get'
  });
}

/**
 * 商户列表导出
 */
export function merchantListDownload(params) {
  return request({
    url: "/admin/platform/export/merchant/info",
    method: 'GET',
    params: params
  });
}

/**
 * 抽奖活动配置
 */
export function activitySave(data) {
  return request({
    url: "/admin/platform/prize/activity/save",
    method: 'POST',
    data: data
  });
}

/**
 * 抽奖活动配置 -列表
 */
export function activityPage(data) {
  return request({
    url: "/admin/platform/prize/activity/page",
    method: 'POST',
    data: data
  });
}

/**
 * 抽奖活动配置 -启动
 */
export function activitysWitch(id) {
  return request({
    url: "/admin/platform/prize/activity/switch/".concat(id),
    method: 'get'
  });
}

/**
 * 抽奖活动配置 -删除
 * @param data
 */
export function activityDelete(id) {
  return request({
    url: "/admin/platform/prize/activity/delete/".concat(id),
    method: 'delete'
  });
}

/**
 * 抽奖活动配置 - ID查询
 * @param data
 */
export function activityGetByid(id) {
  return request({
    url: "/admin/platform/prize/activity/get/byid/".concat(id),
    method: 'get'
  });
}

/**
 * 抽奖活动配置 - 修改
 * @param data
 */
export function activityUpdate(data) {
  return request({
    url: "/admin/platform/prize/activity/update",
    method: 'put',
    data: data
  });
}

/**
 * 抽奖活动配置 - 修改
 * @param data
 */
export function activityPrizeDelete(id) {
  return request({
    url: "/admin/platform/prize/delete/".concat(id),
    method: 'DELETE'
  });
}

/**
 * 抽奖活动配置 - 修改
 * @param data
 */
export function prizeWinningPage(data) {
  return request({
    url: "/admin/platform/prize/winning/page",
    method: 'post',
    data: data
  });
}

/**
 * 抽奖活动配置 - 修改
 * @param data
 */
export function prizeActivitySend(params) {
  return request({
    url: "/admin/platform/prize/activity/send",
    method: 'get',
    params: params
  });
}

/**
 * 秒杀活动-新增
 * @param data
 */
export function addMenus(params) {
  return request({
    url: "/admin/platform/theme/menus/save",
    method: 'POST',
    params: params
  });
}

/**
 * 秒杀活动-修改
 * @param data
 */
export function setMenus(params) {
  return request({
    url: "/admin/platform/theme/menus/update",
    method: 'PUT',
    params: params
  });
}

/**
 * 秒杀活动-通过id查询
 * @param data
 */
export function getMenus(id) {
  return request({
    url: "/admin/platform/theme/menus/get/byid/".concat(id),
    method: 'GET'
  });
}

/**
 * 秒杀活动分页列表
 * @param data
 */
export function getActivityPage() {
  return request({
    url: "/admin/platform/seckill/activity/page",
    method: 'GET'
  });
}