import Layout from '@/layout';
var classification = {
  path: '/classification',
  component: Layout,
  redirect: '/classification',
  name: 'classification',
  meta: {
    title: '帮扶馆',
    icon: 'clipboard'
  },
  children: [{
    path: 'helpHallClassification',
    name: 'classificationStatement',
    meta: {
      title: '帮扶馆分类',
      noCache: true
    },
    component: function component() {
      return import('@/views/classification/helpHallClassification/index');
    }
  }, {
    path: 'listOfHospices',
    name: 'listOfHospicesStatement',
    meta: {
      title: '帮扶馆列表',
      noCache: true
    },
    component: function component() {
      return import('@/views/classification/listOfHospices/index');
    }
  }, {
    //id:商品id，isDisabled：是否能编辑(1不能，2能)，isCopy：是否是采集商品(1是，2不是)
    path: 'listOfHospices/create',
    component: function component() {
      return import('@/views/classification/listOfHospices/create.vue');
    },
    name: 'creatProduct',
    meta: {
      title: '添加帮扶馆',
      noCache: true,
      activeMenu: "/classification/listOfHospices"
    },
    hidden: true
  }, {
    path: 'helpHouseDetails',
    name: 'helpHouseDetailsStatement',
    meta: {
      title: '帮扶馆详情',
      noCache: true
    },
    component: function component() {
      return import('@/views/classification/helpHouseDetails/index');
    }
  }]
};
export default classification;