var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-light"
            ? _vm.variables.menuLightBackground
            : _vm.variables.menuBackground,
      },
    },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color":
                  _vm.settings.sideTheme === "theme-light"
                    ? _vm.variables.menuLightBackground
                    : _vm.variables.menuBackground,
                "text-color":
                  _vm.settings.sideTheme === "theme-light"
                    ? _vm.variables.menuLightColor
                    : _vm.variables.menuColor,
                "unique-opened": true,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": true,
                mode: "vertical",
              },
            },
            _vm._l(_vm.sidebarRouters, function (route) {
              return _c("sidebar-item", {
                key: route.url,
                attrs: { item: route, "base-path": route.url },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }